<template>
  <div
    id="alerts"
    class="flex flex-col lg:text-base px-10 py-5 scroll-smooth"
  >
    <alert-filters
      :is-fetching-alerts="isFetchingAlerts"
      @fetch-alerts="fetchAlerts"
    />
    <spinner
      v-if="isFetchingAlerts"
      fullscreen
    />
    <template v-else>
      <alert-results
        :alerts="visibleAlerts"
        @fetch-alerts="fetchAlerts"
      />
      <scroll-to-top view-id="alerts" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  AlertFilters,
  Spinner,
  AlertResults,
} from '@/components';
import { ScrollToTop } from '@/components/controls';
import { TODAY_FORMATTED, searchFilterTypes } from '@/constants';

export default {
  name: 'Alerts',
  components: {
    ScrollToTop,
    AlertFilters,
    Spinner,
    AlertResults,
  },
  data: () => ({
    isFetchingAlerts: false,
  }),
  computed: {
    ...mapState('alerts', ['searchFilter']),
    ...mapGetters('alerts', ['visibleAlerts']),
    getAlertsAction() {
      switch (this.searchFilter) {
        case searchFilterTypes.asa: return this.getAlertsByAsa;
        case searchFilterTypes.agent: return this.getAlertsByAgentName;
        case searchFilterTypes.client: return this.getAlertsByClientName;
        case searchFilterTypes.evaluationDate:
        default: return this.getAlertsByEvaluationDate;
      }
    },
  },
  async created() {
    this.initializeFilters();
    await this.fetchAlerts(TODAY_FORMATTED);
  },
  methods: {
    ...mapActions('alerts', [
      'getAlertsByEvaluationDate',
      'getAlertsByAsa',
      'getAlertsByAgentName',
      'getAlertsByClientName',
      'initializeFilters',
    ]),
    async fetchAlerts() {
      try {
        this.isFetchingAlerts = true;
        await this.getAlertsAction();
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.isFetchingAlerts = false;
      }
    },
  },
};
</script>
