<template>
  <div class="h-screen flex justify-center items-center px-5">
    <form
      class="w-full max-w-sm"
      @submit.prevent="handleSubmit"
    >
      <img
        class="w-full mb-7"
        src="@/assets/images/ia-logo.png"
      />
      <t-alert
        variant="danger"
        class="mb-5"
        :show="!isValid"
        :dismissible="false"
      >
        {{ errorMessage }}
      </t-alert>

      <div class="mb-5">
        <c-text-input
          v-model="$v.username.$model"
          type="text"
          label="Username"
          :error-message="getDirtyErrorMessage('username')"
        />
        <c-text-input
          v-model="$v.password.$model"
          type="password"
          label="Password"
          :error-message="getDirtyErrorMessage('password')"
        />
      </div>
      <c-button
        class="w-full"
        :isLoading="isLoading"
      >
        Log In
      </c-button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

import { CButton } from '@/components/controls';
import { CTextInput } from '@/components/inputs';
import { getErrorMessage } from '@/utils';

export default {
  name: 'Login',
  components: {
    CButton,
    CTextInput,
  },
  mixins: [validationMixin],
  data: () => ({
    username: '',
    password: '',
    errorMessage: '',
    isLoading: false,
    isDirty: false,
  }),
  computed: {
    isValid: vm => !vm.errorMessage,
  },
  validations: () => ({
    username: {
      required,
      email,
    },
    password: {
      required,
    },
  }),
  methods: {
    ...mapActions('auth', ['login']),
    async handleSubmit() {
      if (!this.isDirty) this.isDirty = true;
      if (this.$v.$invalid) return;

      this.errorMessage = null;
      const { username, password } = this;
      const payload = { username, password };

      try {
        this.isLoading = true;
        await this.login(payload);
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = error;
      } finally {
        this.isLoading = false;
      }
    },
    getDirtyErrorMessage(fieldName) {
      const fieldOptions = this.$v[fieldName];
      if (!this.isDirty || !fieldOptions.$invalid) return null;

      const rules = Object.keys(fieldOptions.$params);
      const errors = rules.filter(rule => !fieldOptions[rule]);

      return getErrorMessage(errors[0]);
    },
  },
};
</script>
